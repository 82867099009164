import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/node_modules/@digitale-kumpel/client-common/src/components/actions/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarWithMenuItems"] */ "/vercel/path0/node_modules/@digitale-kumpel/client-common/src/components/navigation/Navbar/NavbarWithMenuItems.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/vercel/path0/node_modules/@digitale-kumpel/client-common/src/components/sections/Newsletter/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PagePreviewList"] */ "/vercel/path0/node_modules/@digitale-kumpel/client-common/src/components/sections/PagePreviewList/PagePreviewList.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@digitale-kumpel/client-common/src/context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["MainMenu"] */ "/vercel/path0/src/components/MainMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/vercel/path0/src/components/Slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/vercel/path0/src/components/Video.tsx");
