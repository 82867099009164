const routes = {
  SIGN_IN: "/login",
  SIGN_OUT: "/logout",
  REGISTER: "/register",
  MY_COURSES: "/my/courses",
  FORGOT_PASSWORD: "/forgot-password",
  CONTACT: "/contact",
  NEWSLETTER: "/newsletter",
};

export { routes };
