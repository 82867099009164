"use client";
import React from "react";
import { useSession } from "next-auth/react";
import { AuthLink } from "./AuthLink";
import { MainMenuMobile } from "./MainMenuMobile";
import { routes } from "@/config/routes";
import Link from "next/link";

export interface IMainMenu {}
interface IMenuItem {
  href: string;
  label: string;
}
const defaultMenuItems: IMenuItem[] = [
  { href: routes.CONTACT, label: "Kontakt" },
  { href: routes.NEWSLETTER, label: "Newsletter" },
];

const signedInMenuItems = [
  { href: routes.MY_COURSES, label: "Kurse" },
  { href: routes.SIGN_OUT, label: "Ausloggen" },
];

const signedOutMenuItems = [{ href: routes.SIGN_IN, label: "Anmelden" }];

function MainMenu({}: IMainMenu) {
  const session = useSession();

  let menuItems = [...defaultMenuItems];

  if (session?.status === "authenticated") {
    menuItems = [...menuItems, ...signedInMenuItems];
  } else {
    menuItems = [...menuItems, ...signedOutMenuItems];
  }

  return (
    <>
      {/* mobile menu */}
      <div className="md:hidden flex-grow justify-end">
        <MainMenuMobile menuItems={menuItems} />
      </div>
      {/* desktop menu */}
      <div className="hidden md:flex flex-grow gap-4 items-center justify-end">
        {menuItems.map((item) => (
          <Link
            key={item.href}
            href={item.href}
            className="block px-3 py-2 rounded-md text-base text-gray-900 hover:bg-gray-50"
          >
            {item.label}
          </Link>
        ))}
      </div>
    </>
  );
}

export { MainMenu };
